
import { Vue, Component } from "vue-property-decorator";
import { ListDataSource } from "@/data/List/ListDataSource";

@Component
export default class Home extends Vue {
  headers: Array<any> = [
    {
      text: "ID",
      value: "id",
      sortable: true,
      width: 100,
    },
    {
      text: "Имя",
      value: "firstName",
      sortable: false,
    },
    {
      text: "Фамилия",
      value: "lastName",
      sortable: false,
    },

    {
      text: "Статус",
      value: "instanceStateId",
      sortable: false,
    },
  ];

  dataSource: any = new ListDataSource({
    config: {
      pageIndex: 1,
      pageSize: 50,
      orderFieldName: "Id",
      orderFieldDirection: "DESC",
    },
    className: "ClientManager",
  });
  get breadcrumbs() {
    return [
      {
        text: "Главная",
        to: "/",
      },
      {
        text: "Клиентские менеджеры",
        disabled: true,
      },
    ];
  }
}
